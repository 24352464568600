<template>
  <UtilsGenericDropDown
    v-model="country"
    :append-to="appendTo"
    :disabled="disabled"
    :error="Boolean(countryError)"
    :errorMessage="errorMessage"
    :filter-placeholder="$t('chooseacountry')"
    :loading="$store.state.countries.loading"
    :options="$store.state.countries.countriesCodeListRegistration"
    :placeholder="placeholder"
    :required="required"
  />
</template>

<script>
export default {
  emits: ['update:country', 'change'],

  props: {
    required: {
      type: Boolean,
      required: false,
      default: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    countryError: {
      type: String,
      required: false,
      default: '',
    },

    errorMessage: {
      type: String,
      required: false,
      default: '',
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    closeOnScroll: {
      type: Boolean,
      default: false,
    },

    appendTo: {
      type: String,
      default: 'self',
    },
  },

  data() {
    return {
      country: null,
    };
  },

  watch: {
    country(newValue) {
      this.$emit('update:country', newValue);
      this.$emit('change');
    },
  },

  methods: {
    setData(country) {
      this.country = country;
    },
  },
};
</script>
